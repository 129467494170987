import React, { useState, useEffect, useCallback, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { Accordion, AccordionItem, Button } from "@nextui-org/react";

import { MdFavoriteBorder, MdFavorite } from "react-icons/md";

import axios from "axios";
import owl from "../images/owlPeriwinkle.webp";

axios.defaults.baseURL = 'https://searchowl.us:5000';

function ImageCarousel({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const carouselVariants = {
    enter: { opacity: 0, x: "-100%" },
    center: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: "100%" },
  };

  return (
    <div className="relative flex items-center justify-center px-12">
      <button
        onClick={prevSlide}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 text-2xl z-10 px-3 py-2 text-zinc-500"
      >
        &#10094;
      </button>
      <div className="relative w-full h-fit">
        <AnimatePresence initial={false}>
          <motion.img
            key={currentIndex}
            src={images[currentIndex]}
            alt={`Product Image ${currentIndex + 1}`}
            className="w-full h-auto max-w-full"
            initial="enter"
            animate="center"
            exit="exit"
            variants={carouselVariants}
            transition={{ duration: 0.3 }}
          />
        </AnimatePresence>
      </div>
      <button
        onClick={nextSlide}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 text-2xl z-10 px-3 py-2 text-zinc-500"
      >
        &#10095;
      </button>
    </div>
  );
}

function ProductLandingPage() {
  const navigate = useNavigate();
  const { productName, productId } = useParams();
  const [product, setProduct] = useState(null);
  const [discountCode, setDiscountCode] = useState("DISCOUNT10");
  const [copySuccess, setCopySuccess] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isIngredientsExpanded, setIsIngredientsExpanded] = useState(false);
  const ingredientsRef = useRef(null);
  const [shouldTruncateIngredients, setShouldTruncateIngredients] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(discountCode);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const checkFavoriteStatus = useCallback((productId) => {
    const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
    return favorites.some((fav) => fav.id === productId);
  }, []);

  const handleProductClick = async (event) => {
    event.preventDefault();
    const productId = event.currentTarget.getAttribute("data-product-id");
    const searchId = localStorage.getItem("searchId");
    const siteUrl = event.currentTarget.href;
    const clickedAt = new Date().toISOString();
  
    const newTab = window.open(siteUrl, "_blank");
  
    const checkTabStatus = async () => {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (newTab.closed) {
            clearInterval(interval);
            resolve();
          }
        }, 1000);
      });
    };
  
    await checkTabStatus();
  
    const leftAt = new Date().toISOString();
    const timeSpent = (Date.parse(leftAt) - Date.parse(clickedAt)) / 60000;
  
    try 
    {
      await axios.post(
        `/api/site-click`,
        {
          searchId,
          productId,
          clickedAt,
          leftAt,
          timeSpent,
        }
      );
    } 
    catch (error) 
    {
      console.error("There was an error recording the click event!", error);
    }
  };

  useEffect(() => {
    const fetchProduct = async (query) => {
      try 
      {
        const response = await axios.get(
          `/api/product`,
          { params: { q: query } }
        );
        //console.log(response);
        setProduct(response.data[0].Item);
        setIsFavorite(checkFavoriteStatus(response.data[0].Item.id));
      } 
      catch (error) 
      {
        console.error("There was an error fetching the products!", error);
      }
    };

    fetchProduct(productId);
  }, [productId, checkFavoriteStatus]);

  useEffect(() => {
    if (ingredientsRef.current) {
      const maxHeight = 200; // Approximately 5 lines of text (adjust as needed)
      setShouldTruncateIngredients(ingredientsRef.current.scrollHeight > maxHeight);
    }
  }, [product]);

  const toggleFavorite = useCallback(() => {
    if (product) {
      const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
      let updatedFavorites;

      if (isFavorite) {
        updatedFavorites = favorites.filter((fav) => fav.id !== product.id);
      } else {
        const newFavorite = {
          id: product.id,
          image: product.image,
          name: product.name,
          price: product.sale_price,
          product_id: product.id,
        };
        updatedFavorites = [...favorites, newFavorite];
      }

      localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
      setIsFavorite(!isFavorite);
    }
  }, [product, isFavorite]);

  const toggleIngredients = () => {
    setIsIngredientsExpanded(!isIngredientsExpanded);
  };

  if (!product) {
    return (
      <div className="flex flex-col min-h-screen">
        <Header />
        <div className="flex h-screen w-full items-center justify-center">
          <div className="flex flex-col items-center space-y-4">
            <div className="animate-bounce">
              <img src={owl} alt="Loading" className="h-12 w-12" />
            </div>
            <p className="text-gray-500 dark:text-gray-400">Loading...</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  const productImages = product ? [product.image] : [];
  const link = `https://searchowl.us/legal/disclaimer`;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeIn" }}
    >
      <div className="flex flex-col">
        <Header />
        <main className="flex flex-col items-start px-4 sm:px-6 lg:px-10 w-full">
          <button
            onClick={() => navigate(-1)}
            className="px-1 py-3 text-2xl font-semibold whitespace-nowrap text-black max-md:px-5"
          >
            ← Back to Search
          </button>
          <section className="w-full">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <figure className="flex flex-col items-center w-full">
                <ImageCarousel images={productImages} />
              </figure>
              <article className="w-full">
                <div className="flex-col justify-center font-medium text-zinc-500">
                  <h2 className="text-4xl font-semibold leading-10 text-black">
                    {product.name}
                  </h2>
                  <p className="mt-6 text-2xl">{product.brand}</p>
                  <Rating
                    name="read-only"
                    value={product?.rating || 3}
                    precision={0.1}
                    readOnly
                  />
                  <p className="text-2xl text-black">${Number(product.sale_price).toFixed(2)}</p>
                  <p className="mt-6 text-xl leading-8">Ingredient List</p>
                  <div 
                    ref={ingredientsRef}
                    className={`mt-2 text-xl leading-8 ${!isIngredientsExpanded && shouldTruncateIngredients ? 'line-clamp-4' : ''}`}
                  >
                    {product.ingredients}
                  </div>
                  {shouldTruncateIngredients && (
                    <Button
                      size="sm"
                      color="primary"
                      variant="shadow"
                      onClick={toggleIngredients}
                      className="p-0"
                    >
                      {isIngredientsExpanded ? 'View less' : 'View more'}
                    </Button>
                  )}
                  <div className="flex flex-row">
                    <p className="mt-6 text-sm">
                      ** Disclaimers: <br></br> We are a customer first, ad-free
                      platform that reviews everything we recommend. When buying
                      with our links, we may earn a commission. &nbsp;
                      <Link to={link} className="text-sm underline">
                      Learn More
                      </Link>
                    </p>
                  </div>

                  <div className="flex flex-wrap items-center mt-6">
                    <a
                      className="justify-center items-center px-10 py-3.5 text-base text-white bg-black rounded-lg shadow-sm buy-now-button"
                      href={product.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-product-id={productId}
                      onClick={handleProductClick}
                    >
                      Buy Now
                    </a>
                    {product.coupon_code && (
                      <button
                      className="justify-center items-center px-10 py-3.5 ml-4 text-base text-white bg-black rounded-lg shadow-sm"
                      onClick={copyToClipboard}
                    >
                      {copySuccess ? "Copied!" : "Discount Code"}
                    </button>
                    )}
                  </div>
                </div>
                <Accordion variant="contained">
                  <AccordionItem
                  key="1"
                  aria-label="About this Product"
                  title={
                    <span className="w-full text-2xl p-0">
                      About this Product
                    </span>
                  }
                >
                  <div className="w-fit text-xl text-slate-500">
                    {product.description}
                  </div>
                </AccordionItem>    
                </Accordion>
              </article>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </motion.div>
  );
}

export default ProductLandingPage;
